import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';

export type InfoIconProps = Omit<FontAwesomeIconProps, 'icon'>;

export const InfoIcon: React.FC<InfoIconProps> = (props) => (
  <FontAwesomeIcon icon={faCircleQuestion} {...props} />
);
