import { CartContext } from 'node_modules/@shopify/hydrogen/dist/esnext/components/CartProvider/context';
import Button from './button.client';
import { PartialDeep } from 'type-fest';
import {
  SellingPlan,
  ProductVariant,
} from '@shopify/hydrogen/dist/esnext/storefront-api-types';
import { useContext } from 'react';
import * as Sentry from '@sentry/nextjs';
import { usePlausible } from 'next-plausible';
import { PlausibleAnalyticEvents } from '../types';
import { sendShopifyAnalytics, AnalyticsEventName } from '@shopify/hydrogen-react';
import { ShopifyAddToCartPayload} from '@shopify/hydrogen-react/dist/types'


declare global {
  interface Window {
    _learnq?: any[];
  }
}

interface AddToCartButtonProps {
  selectedVariant: PartialDeep<ProductVariant> | null | undefined;
  selectedSellingPlan: PartialDeep<SellingPlan> | null | undefined;
}

export default function AddToCartButton({
  selectedVariant,
  selectedSellingPlan,
}: AddToCartButtonProps) {
  const plausible = usePlausible<PlausibleAnalyticEvents>();

  const context = useContext(CartContext);
  const cartId = context?.id

  const addToCart = () => {
    if (context && selectedVariant?.id) {
      context.linesAdd([
        {
          quantity: 1,
          merchandiseId: selectedVariant?.id,
          attributes: [
            {
              key: 'type',
              value: selectedSellingPlan ? 'subscription' : 'single test',
            },
          ],
          sellingPlanId: selectedSellingPlan?.id,
        },
      ]);
      plausible('Add To Cart', {
        props: {
          selectedVariant: selectedVariant.id,
          selectedSellingPlan,
        },
      });
   
     sendShopifyAnalytics({
       eventName: AnalyticsEventName.ADD_TO_CART,
        payload: cartId as unknown as ShopifyAddToCartPayload

     });

      const item = {
        id: selectedVariant.id,
        name: selectedVariant.title,
        price: selectedVariant.price,
        sellingPlan: selectedSellingPlan ? 'subscription' : 'single test',
      };
     

      const _learnq = window._learnq || [];
      _learnq.push(['track', 'Added to Cart', item]);
    } else {
      Sentry.captureException(new Error('Cart context not found'));
    }
  };

  return (
    <Button id="add-to-cart" onClick={addToCart}>
      Add to cart
    </Button>
  );
}
