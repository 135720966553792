import { useEffect } from 'react';
import { ClientAnalytics, ShopifyAnalyticData } from './client-analytics';

export function useShopifyAnalytics(
  pageType: string,
  analytics: ShopifyAnalyticData
) {
  useEffect(() => {
    ClientAnalytics.publish(ClientAnalytics.eventNames.PAGE_VIEW, false, {
      shopify: {
        ...analytics.shopify,
        pageType,
      },
    });
  }, [analytics.shopify, pageType]);
}
