import { InfoIcon } from '@snapi/icons';
import { MouseEvent, useRef } from 'react';
import { motion } from 'framer-motion';
import { useClickAway, useToggle } from 'react-use';
import clsx from 'clsx';

interface ClueCardProps {
  title?: string;
  description: string;
  orientation?: 'top' | 'bottom';
  href?: string;
}

const ClueIconVariants = {
  open: { rotate: -45 },
  closed: { rotate: 0 },
};

const ClueVariants = {
  initial: {
    opacity: 0,
    height: 0,
    duration: 0,
  },
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
};

export const ClueCard = ({
  title,
  description,
  orientation = 'top',
  href,
}: ClueCardProps) => {
  const [on, toggle] = useToggle(false);

  const clickAwayElementRef = useRef(null);
  useClickAway(clickAwayElementRef, () => {
    if (on) toggle();
  });

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    toggle();
  };

  return (
    <span onClick={handleClick} ref={clickAwayElementRef}>
      <motion.span
        animate={on ? 'open' : 'closed'}
        variants={ClueIconVariants}
        transition={{ duration: 0.5 }}
      >
        <InfoIcon className="cursor-pointer" />
      </motion.span>
      <motion.div
        initial={{ opacity: 0 }}
        animate={on ? 'open' : 'closed'}
        transition={{ duration: 0.5 }}
        style={{ overflow: 'hidden' }}
        variants={ClueVariants}
        className={clsx(
          orientation === 'top' ? 'bottom-0' : 'top-0',
          'absolute left-0 right-0 flex flex-1 justify-end z-10 '
        )}
      >
        <div className="xl:w-[236px] lg:w-[200px] p-3 rounded-[10px] leading-none shadow-lg bg-white self-end m-4 cursor-pointer">
          {title && <span className="font-mono">{title}</span>}
          {href ? (
            <a
              href={href}
              className="font-mono text-[13px] underline mt-2 text-left"
            >
              {description}
            </a>
          ) : (
            <p className={clsx(title ? 'text-xs mt-2' : 'text-xs')}>
              {description}
            </p>
          )}
        </div>
      </motion.div>
    </span>
  );
};
