import { useEffect, useState } from 'react';
import Image, { StaticImageData } from 'next/image';
import { useProductOptions } from '@shopify/hydrogen';
import { motion, AnimatePresence } from 'framer-motion';
import clsx from 'clsx';
import { PartialDeep } from 'type-fest';
import { Product } from '@shopify/hydrogen/dist/esnext/storefront-api-types';
import AddToCartButton from '../base/add-to-card-button';
import BuyNowBlueImg from './images/blue-buy.png';
import BuyNowPinkImg from './images/pink-buy.png';
import { Description } from '@snapi/luxury';

const LineIcon = ({ color }) => {
  return <div className={clsx([color], 'my-5 border-b  max-w-[558px]')} />;
};

// TODO replace with a span
const RadioButtonIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke={color} />
    </svg>
  );
};

// TODO replace with a span
const RadioButtonCheckedIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="9.5" stroke={color} />
      <circle cx="10" cy="10" r="7" fill={color} />
    </svg>
  );
};

// TODO replace with a span
const Point = ({ color }) => {
  return (
    <svg
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill={color} />
    </svg>
  );
};

const BuyPoints = {
  single: [
    "Single Test is the most convenient way to take a snapshot of your baby's gut health.",
    "Interested in monitoring your baby's gut and health conditions more frequently? Try our subscription plan.",
  ],
  subscription: [
    '1 test every 3 months',
    'Detailed reports that compare previous tests to new results',
    'Skip or cancel anytime',
  ],
};

const PointVariants = {
  visible: {
    opacity: 1,
    height: 'auto',
    zIndex: 10,
  },
  hidden: {
    opacity: 0,
    height: 0,
    transitionEnd: { zIndex: -10 },
  },
};

function PurchasePoints({
  children,
  points,
  visible,
  textColor,
  onClick,
}: {
  children: React.ReactNode;
  points: string[];
  visible: boolean;
  onClick: () => void;
  textColor: string;
}) {
  return (
    <div className="flex">
      <div className="flex cursor-pointer mr-2 sm:mr-5 mt-1" onClick={onClick}>
        {visible ? (
          <RadioButtonCheckedIcon color={textColor} />
        ) : (
          <RadioButtonIcon color={textColor} />
        )}
      </div>
      <div className="flex flex-1 flex-col">
        <div className="flex flex-1 cursor-pointer" onClick={onClick}>
          {children}
        </div>
        <AnimatePresence>
          <motion.div
            variants={PointVariants}
            animate={visible ? 'visible' : 'hidden'}
            transition={{ duration: 0.5 }}
          >
            <div className="flex flex-1 flex-col max-w-[546px]">
              {points.map((point) => (
                <div className="flex mt-3" key={point}>
                  <div>
                    <div className="flex">
                      <span className="mr-2 mt-2">
                        <Point color={textColor} />
                      </span>
                      <span>{point}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}

interface SelectTypeProps {
  productPrice: string;
  color: string;
}

function SelectType({ color, productPrice }: SelectTypeProps) {
  const [checkSub, setCheckSub] = useState(true);
  const [checkSingle, setCheckSingle] = useState(false);

  const { setSelectedSellingPlan, selectedVariant } = useProductOptions();

  useEffect(() => {
    if (selectedVariant) {
      setSelectedSellingPlan(
        // @ts-expect-error could be undefined
        selectedVariant?.sellingPlanAllocations?.nodes?.[0]?.sellingPlan
      );
    }
  }, []);

  const onChangeRadioButtonSub = () => {
    setCheckSingle(false);
    setCheckSub(true);

    setSelectedSellingPlan(
      // @ts-expect-error could be undefined
      selectedVariant?.sellingPlanAllocations?.nodes?.[0]?.sellingPlan
    );
  };

  const onChangeRadioButtonSingle = () => {
    setCheckSingle(true);
    setCheckSub(false);
    setSelectedSellingPlan(null);
  };

  return (
    <div>
      <PurchasePoints
        textColor={color}
        points={BuyPoints.single}
        visible={checkSingle}
        onClick={onChangeRadioButtonSingle}
      >
        <h4 className=" text-xl">
          Single test — ${parseFloat(productPrice).toFixed(0)}
        </h4>
      </PurchasePoints>

      <LineIcon color={color} />

      <PurchasePoints
        textColor={color}
        points={BuyPoints.subscription}
        visible={checkSub}
        onClick={onChangeRadioButtonSub}
      >
        <h4 className=" text-xl">
          Snapi Subscription plan — $99
          <span className="text-sm font-normal ml-2">every test</span>
        </h4>
      </PurchasePoints>
    </div>
  );
}

type BuySectionPreset = 'pink' | 'blue';

interface BuySectionProps {
  preset: BuySectionPreset;
  product: PartialDeep<Product>;
}

const BuySectionPresetStyles: {
  [key in BuySectionPreset]: {
    color: string;
    spanColor: string;
    img: StaticImageData;
    textColor: string;
  };
} = {
  pink: {
    textColor: '#6B444B',
    color: 'bg-pink',
    spanColor: 'text-pink-dark',
    img: BuyNowPinkImg,
  },
  blue: {
    textColor: '#3F576D',
    color: 'bg-blue',
    spanColor: 'text-blue-dark',
    img: BuyNowBlueImg,
  },
};

export function BuySection({ preset, product }: BuySectionProps) {
  const { color, spanColor, img, textColor } = BuySectionPresetStyles[preset];

  const productPrice = product?.priceRange?.maxVariantPrice?.amount;
  const { selectedSellingPlan, selectedVariant } = useProductOptions();

  if (!productPrice) {
    return null;
  }

  return (
    <section id="buy">
      <div className="container flex flex-col lg:flex-row justify-center lg:h-[650px] xl:h-[630px]">
        <div className="flex flex-1 sm:min-h-[428px] lg:h-[650px] xl:h-[630px] min-h-[320px] relative">
          <Image
            src={img}
            alt=""
            layout="fill"
            className="object-cover w-full object-center
              rounded-tl-3xl rounded-tr-3xl rounded-bl-none rounded-br-none
            lg:rounded-tl-3xl lg:rounded-tr-none lg:rounded-bl-3xl lg:rounded-br-none"
          />
        </div>
        <div
          id="buy-variants"
          className={clsx(
            [color],
            'flex flex-1 flex-col px-5 py-10 rounded-b-3xl sm:px-10 lg:rounded-b-none lg:rounded-r-3xl lg:px-7'
          )}
        >
          <div className={clsx([spanColor], 'flex flex-1 flex-col')}>
            <h2
              className={clsx([spanColor], 'mb-3  font-semibold max-w-[432px]')}
            >
              We think you’ll love Snapi, if not, it’s on us!
            </h2>
            <div className=" mb-2 max-w-[546px]">
              <p>
                We&apos;re here to provide you with a seamless and accessible way to 
                support your baby&apos;s gut health. If you aren&apos;t satisfied with your 
                experience, let us know within 60 days of receiving your test 
                results for a full refund!
              </p>
            </div>

            <LineIcon color={textColor} />
            <div className="mb-10">
              <SelectType productPrice={productPrice} color={textColor} />
            </div>
          </div>
          <div className="flex gap-4 flex-col sm:flex-row items-center">
            <AddToCartButton
              selectedVariant={selectedVariant}
              selectedSellingPlan={selectedSellingPlan}
            />
            <div className={clsx(spanColor)}>
              <Description>FREE SHIPPING • HSA / FSA eligible</Description>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
